.addlist .el-icon-plus:before {
  content: "\e6d9";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-switch .el-switch__core {
  width: 40px !important;
}
.table-switch .el-switch__core:after {
  background-color: #fff;
}
.table-switch span {
  line-height: 20px;
  margin-left: 5px;
}
.operationControl > div > div > span {
  min-width: 6rem;
}
.itemLabel {
  display: inline-block;
  width: 3rem;
}
.operationControl {
  position: relative;
}
.questionBox > div {
  margin-bottom: 15px;
}
.questionBox > div:last-child {
  margin-bottom: 0;
}
.questionInner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;
}
.questionInner:hover {
  background: #ecf5ff;
}
.questionInnerLeft {
  position: relative;
  width: 100%;
}
.questionTitle {
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.questionType {
  white-space: nowrap;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px 8px;
  font-size: 14px;
  color: #2878ff;
  background: #fff;
  border: 1px solid #2878ff;
}
.questionOption {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
}
.questionOption span {
  width: 25%;
  padding-right: 8px;
  margin-bottom: 10px;
}
.questionAnswer {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;
}
.questionAnswer > span {
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.questionAnswer .questionAnswerS {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(133, 206, 97, 0.3);
}
.questionAnswer .questionAnswerS:last-child {
  border: 0;
}
.questionAnalysis {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;
}
.questionAnalysis > span {
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: #e6a23c;
  background: #fdf6ec;
  border-color: #f5dab1;
}
.knowledge {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  width: 100%;
}
.knowledge > span {
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.knowledge .questionAnswerS {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(133, 206, 97, 0.3);
}
.knowledge .questionAnswerS:last-child {
  border: 0;
}
.questionInnerRight {
  min-width: 156px;
  margin-left: 15px;
}
.showChildrenQuestion {
  cursor: pointer;
  color: #2878ff;
}
.childrenQuestionItem {
  position: relative;
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  margin-right: -169px;
}
.exportdialog1 .el-dialog__title {
  font-size: 16px;
}
.exportdialog1 .export-box {
  width: 100%;
  margin-bottom: 20px;
}
.exportdialog1 .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
.exportdialog1 .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
.exportdialog1 .export-box > div {
  padding-left: 30px;
}
.exportdialog1 .export-box .el-button.is-disabled,
.exportdialog1 .export-box .el-button.is-disabled:focus,
.exportdialog1 .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
.exportdialog1 /deep/ .el-upload {
  width: 80px;
  height: 35px;
  border: 0;
}
.exportdialog1 /deep/ .el-upload .el-button {
  width: 80px !important;
  height: 33px;
  line-height: 33px;
  padding: 0 20px;
}
.docsDialog .el-form-item {
  margin-bottom: 0.5rem;
}
.studentNum {
  margin: 20px 0 10px;
}
.studentNum span {
  margin: 0 10px;
}
.hh {
  word-break: break-all;
  word-wrap: break-word;
}
