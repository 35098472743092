












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-switch__core {
    width: 40px !important;
  }

  .el-switch__core:after {
    background-color: #fff;
  }

  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl>div>div>span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}

.operationControl {
  position: relative;
}


//题目样式
.questionBox>div {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

//.questionItem{
//  margin-bottom: 15px;
//  &:last-child{
//    margin-bottom: 0;
//  }
//}
.questionInner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;

  &:hover {
    background: #ecf5ff;
  }
}

.questionInnerLeft {
  position: relative;
  width: 100%;
}

.questionTitle {
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.questionType {
  white-space: nowrap;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px 8px;
  font-size: 14px;
  color: #2878ff;
  background: #fff;
  border: 1px solid #2878ff;
}

.questionOption {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;

  span {
    width: 25%;
    padding-right: 8px;
    margin-bottom: 10px;

  }
}

.questionAnswer {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;

  &>span {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }

  .questionAnswerS {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(133, 206, 97, 0.3);

    &:last-child {
      border: 0;
    }
  }
}

.questionAnalysis {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;

  &>span {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1;
  }
}

.knowledge {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  width: 100%;

  &>span {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }

  .questionAnswerS {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(133, 206, 97, 0.3);

    &:last-child {
      border: 0;
    }
  }
}

.questionInnerRight {
  min-width: 156px;
  margin-left: 15px;
}

.showChildrenQuestion {
  cursor: pointer;
  color: #2878ff;
}

.childrenQuestionItem {
  position: relative;
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  margin-right: -169px;
  //.questionTitle,.questionAnswer,.questionAnalysis,.questionOption{
  //  width: calc(100% - 149px);
  //}
  //.mt16{
  //  position: absolute;
  //  width: 149px;
  //  top: 0;
  //  right: 0;
  //}
  //&:last-child{
  //  margin-bottom: 0;
  //}
}

//导入题库
.exportdialog1 {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }

  /deep/.el-upload {
    width: 80px;
    height: 35px;
    border: 0;

    .el-button {
      width: 80px !important;
      height: 33px;
      line-height: 33px;
      padding: 0 20px;
    }
  }
}

.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}

.studentNum {
  margin: 20px 0 10px;

  span {
    margin: 0 10px;
  }
}

//强制换行
.hh {
  word-break: break-all;
  word-wrap: break-word;
  //white-space: pre-wrap;
}
